async function qlikLogin(token ,  tenanturl , webId) {
    if (!tenanturl.endsWith("/")) tenanturl += '/';
    var url = tenanturl+'login/jwt-session?qlik-web-integration-id=' + webId;
    var finalToken = 'Bearer ' + token;

    const withAsync = async fn => {
        try {
            const response = await fn()
            return [response, null]
        } catch (error) {
            return [null, error]
        }
    }

    const result = await withAsync(() => fetch(url,
        {
            method: "POST",
            credentials: "include",
            mode: "cors",
            headers: {
                "content-type": "application/json",
                Authorization: finalToken,
                "qlik-web-integration-id": webId
            },
            rejectunAuthorized: false
        }

    ).then(response => response.text()))
    return result;

}






